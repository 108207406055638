import React from 'react';
import grainImage from '../assets/grain_beetle.jpg';
import logisticsImage from '../assets/shipping_container.jpeg';
import portImage from '../assets/ship.jpeg';
import hexImage from '../assets/hex.jpeg';

const Services = () => {
  const sectionStyle = {
    background: `url(${hexImage}) repeat center`,
    backgroundSize: 'contain',
    border: '1px solid #d0d0d0',
    marginBottom: '1rem',
  };


  return (
    <section id="services" className="pl-2 pr-2">
      <section id="services" className="p-0 grid gap-4 md:grid-cols-3">
        <div className="service bg-white p-1" style={sectionStyle}>
          <img src={grainImage} alt="Grain" className="h-90 mb-4 text-center shadow-md" />
          <h2 className="text-xl font-bold mb-2 text-aps-red text-center" alt="Grain Services">GRAIN SERVICES</h2>
          <div className='mx-auto mb-2 mt-2 h-px w-40 mt-1 mb-1 text-center' style={{background: '#dddddd'}}></div>
          <p className="text-gray-800 p-2">Our fumigation & pest management services offer a comprehensive solution to safeguard your precious harvest. With our expertise in pest management, we ensure that your grain remains free from infestations, protecting your investment and reputation. <br /><br />Our tailored approach considers your specific needs and the unique challenges of your operation. By employing state-of-the-art techniques and environmentally friendly products, we guarantee effective pest eradication while prioritizing the safety of your crop and the surrounding environment. <br /><br />Don’t let pests compromise your hard work and profits. Partner with us for reliable fumigation services and enjoy peace of mind knowing that your grain is in expert hands. Schedule a consultation today to learn more about how we can support your success. We also provide services for organic food products. With the latest technology and methods in fumigation and pest management, we protect those commodities throughout the entire process. Safeguard your grain, safeguard your future. Contact us now.</p>
        </div>

        <div className="service bg-white p-1" style={sectionStyle}>
          <img src={logisticsImage} alt="Logistics & Warehousing" className="h-90 mb-4 text-center shadow-md" />
          <h2 className="text-xl font-bold mb-2 text-aps-red text-center" alt="Logistics & Warehousing Services">LOGISTICS &amp; WAREHOUSING SERVICES</h2>
          <div className='mx-auto mb-2 mt-2 h-px w-40 mt-1 mb-1 text-center' style={{background: '#dddddd'}}></div>
          <p className="text-gray-800 p-2">Pests and infestations causing disruptions to your logistics and warehousing operations? Our comprehensive fumigation, sanitation, and pest management services are tailored to keep your facilities running smoothly. With our expertise, we’ll eliminate any existing pest problems and implement preventive measures to ensure they don’t return. <br /><br />Partner with us to safeguard your inventory, protect your reputation, and maintain compliance with industry regulations. Let us handle the pests, so you can focus on your core business activities. <br /><br />Contact us today to schedule a consultation and take the first step towards a pest-free environment.</p>
        </div>

        <div className="service bg-white p-1" style={sectionStyle}>
          <img src={portImage} alt="Port & Compliance" className="h-90 mb-4 text-center shadow-md" />
          <h2 className="text-xl font-bold mb-2 text-aps-red text-center" alt="Port & Compliance Services">PORT &amp; COMPLIANCE SERVICES</h2>
          <div className='mx-auto mb-2 mt-2 h-px w-40 mt-1 mb-1 text-center' style={{background: '#dddddd'}}></div>
          <p className="text-gray-800 p-2">We proudly serve the Port of Houston and all other Texas ports of entry in all compliance needs. Safeguarding, fumigation, cleaning, and removal. Our certified specialists have been working side by side with USDA and Customs for over 25 years. <br /><br />Receive an emergency action notice? Call us right away to address those needs and prevent further fines. We are your 24/7 service provider. We look forward to being of assistance and answering any questions that you may have.</p>
        </div>
      </section>
    </section>
  );
};

export default Services;
