import React from 'react';
import grainBackground from '../assets/grain.jpg';

const Home = () => {
  const sectionStyle = {
    background: `url(${grainBackground}) no-repeat center`,
    backgroundSize: 'cover',
    height: '30vh', // Set a fixed height
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: '2rem',
    textAlign: 'center',
  };

  return (
    <section id="home" style={sectionStyle}>
      <div style={{ paddingTop: '10vh' }}>

      </div>
    </section>
  );
};

export default Home;