import React from 'react';
import { FaPhoneSquareAlt } from "react-icons/fa";

const Header = () => {
  const headerStyle = {
    display: 'flex',
    color: 'white',
    fontSize: '1.1rem',
    borderBottom: '1px solid #000',
    minHeight: '50px',
  };

  return (
    <header className="items-center justify-between p-2 text-white text-middle" style={headerStyle}>
      <div className="flex items-center">
        <img src="/aps_logo_r.png" alt="Aegis Port Services" className="h-10" />
        <span className="ml-2 text-1xl md:text-2xl mb-1 font-semibold text-aps-red">
          <a href="http://www.aegisserves.com/">Aegis Port Services</a>
        </span>
      </div>
      <span className="ml-2 text-sm md:text-1xl text-aps-red" style={{display: "flex", justifyContent: "center"}}>
        <FaPhoneSquareAlt style={{marginTop: '5px', marginRight: '5px'}}/><a href="tel:+1800.451.0835">(800) 451-0835</a>
      </span>
    </header>
  );
};

export default Header;