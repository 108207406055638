import React from 'react';

const SuccessModal = ({ onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center">
      <div className="fixed inset-0 bg-gray-800 opacity-75"></div>
      <div className="relative bg-white p-8 rounded-lg shadow-lg text-center">
        <h2 className="text-xl font-bold mb-4">Service Request Sent</h2>
        <p>Someone will contact you shortly.</p>
        <button className="w-fit bg-black text-white py-2 mt-6 px-4 rounded-md hover:bg-gray-600" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default SuccessModal;