import React from 'react';
import flagBackground from '../assets/flag.png';


const Intro = () => {
  const introStyle = {
    display: 'flex',
    color: 'white',
    fontSize: '1.2rem',
  };

  return (
    <section id="intro" className="p-2" style={introStyle}>
      <div className="mx-auto pl-6 pr-6 pt-8 pb-8 text-left">
        <h2 className="text-2xl font-bold mb-2 text-aps-red text-center">Certified, Compliant, Insured, and Veteran Owned</h2>
        <div className='mx-auto mb-2 mt-2 h-px mt-1 mb-1 text-center' style={{background: '#dddddd', width: '30%'}}></div>
        <p className="text-gray-800">Our specialists have served Texas for over 25 years. <a href="http://www.aegisserves.com">Aegis Port Services</a> is a commercial fumigation & pest management company specializing in grain, USDA compliance, warehousing, logistics, imports, and exports. We proudly customize our services per the individual needs of those we serve.</p>
      </div>
    </section>
  );
};

export default Intro;