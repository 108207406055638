import React, { useState } from 'react';
import { send } from 'emailjs-com';
import SuccessModal from '../components/success_modal';

const Contact = () => {
  const [sendSuccess, setSendSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    bol: '',
    ean: '',
    message: '',
  });

  const handleInputChange = (event) => {
    setFormData({
        ...formData,
        [event.target.name]: event.target.value
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    send(
      'service_5qv7f6u',
      'template_f8www0r',
      formData,
      'cCUkCrPbsmzFqNyNp'
    )
    .then((response) => {
      setSendSuccess(true);
      setShowModal(true);
    })
    .catch((err) => {
      setSendSuccess(false);
      setShowModal(false);
      console.log('FAILED...', err);
    });
  }

  const closeModal = () => {
    setShowModal(false);
  };

  const sectionStyle = {
    borderTop: '1px solid #c0c0c0',
  };

  return (
    <section id="contact" className="p-2 bg-gray-200" style={sectionStyle}>
      <div className="container p-2">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pl-2">
          <div>
            <h3 className="text-2xl font-semibold mb-2 text-aps-red">Request Information</h3>
            <form onSubmit={onSubmit}>
              <div className="mb-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">Bill of Lading</label>
                <input
                  type="text"
                  id="bol"
                  name="bol"
                  className="mt-1 p-2 block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  value={formData.blo}
                  onChange={handleInputChange}
                  />
              </div>
              <div className="mb-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">Emergency Action Notice Serial Number</label>
                <input
                  type="text"
                  id="ean"
                  name="ean"
                  className="mt-1 p-2 block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  value={formData.ean}
                  onChange={handleInputChange}
                  />
              </div>
              <div className="mb-4">
                <label htmlFor="name"
                className="block text-sm font-medium text-gray-700">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="mt-1 p-2 block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  value={formData.name}
                  onChange={handleInputChange}
                  />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="mt-1 p-2 block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  value={formData.email}
                  onChange={handleInputChange}
                  />
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="block text-sm font-medium text-gray-700">Service Request Description</label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  className="mt-1 p-2 block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  value={formData.message}
                  onChange={handleInputChange}
                  ></textarea>
              </div>
              <button
                  type="submit"
                  className="bg-black text-white py-2 px-4 rounded-md hover:bg-gray-600"
                >Send Request</button>
            </form>
            {showModal && <SuccessModal onClose={closeModal} />}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;