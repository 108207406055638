import React from 'react';
import './App.css';
import Header from './containers/header';
import Footer from './containers/footer';

import { Home, Contact, Intro, Services } from './pages';

const App = () => {
  return (
    <div>
      <Header />

      <div className="sections">
        <Home />
        <Intro />
        <Services />
        <Contact />
      </div>

      <Footer className="footer" />
    </div>
  );
};

export default App;